import React, {useEffect, useMemo, useState} from 'react';
import useScreenSize from "../helpers/hooks/useScreenSize";
import {light, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomSelect from "./UI/CustomSelect";
import {Btn, ClickableDiv, FlexColumnSection, FlexRowSection, Header, SmallHeader} from "../styles/globalStyles";
import {Collapse, Drawer, Radio, Space, Popover} from "antd";
import {t} from "i18next";
import './Filters.scss'
import orderBy from "lodash-es/orderBy";
import styled from "styled-components";
import {addFiltersToQueryParams, removePropFromQueryParams} from "../helpers/functions";
import { useSearchParams, useNavigate } from 'react-router-dom';

const FiltersHandler = (props) => {
    const { initConfig, unfilteredData, onFiltered, oldStyle, pageName } = props;
    const [config, setConfig] = useState(JSON.parse(JSON.stringify(initConfig)));
    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [openDesktopFilters, setOpenDesktopFilters] = useState(false);
    const [collapseConfig, setCollapseConfig] = useState(null);
    const [filtersQueryObj, setFiltersQueryObj] = useState(null);
    const {isMobile} = useScreenSize()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
    const hasActiveFilters = useMemo(() => config ? Object.values(config).some(filter => filter.isMulti && filter.selected.length > 0) : false, [config]);
    const numActiveInnerFilters = useMemo(() => config ? Object.values(config).filter(filter => !filter.outerDisplay && ((filter.isMulti && filter.selected.length > 0) || (!filter.isMulti && filter.selected !== filter.defaultValue))).length : 0, [config]);
    const hasInnerFilters = useMemo(() => config ? Object.values(config).some(filter => !filter.outerDisplay) : false, [config]);

    useEffect(() => {
        let initFilterObj = {}
        if(params?.filters) {
            const filterObj = JSON.parse(params.filters)
            if(filterObj.pageName === pageName) {
                initFilterObj = filterObj
            }
        }
        setFiltersQueryObj(initFilterObj)

        return () => {
            // on page unmount - remove filters from url if this is a page change and not a page refresh
            if(!window.location.pathname.includes(pageName)) { // window.location.pathname has the next page whereas location.pathname has the current page, so we are using window.location.pathname here
                console.log('removing filters on page change')
                removePropFromQueryParams(navigate,'filters')
            }
        }
    }, []);

    useEffect(() => {
        if(filtersQueryObj) {
            if(Object.keys(filtersQueryObj).length) {
                if(params.filters !== JSON.stringify(filtersQueryObj)) {
                    addFiltersToQueryParams(navigate, {filters: JSON.stringify({...filtersQueryObj, pageName})})
                }
            } else {
                if(params.filters) {
                    removePropFromQueryParams(navigate, 'filters')
                }
            }
        }
    }, [filtersQueryObj]);

    useEffect(() => {
        if(initConfig && config) {
            let filterObj = (params.filters && JSON.parse(params.filters).pageName === pageName) ? JSON.parse(params.filters) : {}
            const newConfig = Object.keys(initConfig).reduce((acc, filterType) => {
                let querySelection = filterObj[filterType]
                const defaultSelection = config[filterType]?.selected ?? initConfig[filterType]?.selected
                if(querySelection) {
                    const currentFilter = initConfig[filterType]
                    querySelection = querySelection.reduce((acc, selected) => {
                        const exist = currentFilter.options.some(option => option[currentFilter.valueProp] === parseInt(selected))
                        return exist ? [...acc, selected] : acc
                    }, [])
                }
                return ({...acc, [filterType]: {...initConfig[filterType], selected: querySelection ?? defaultSelection}})
            }, {})
            console.log('newConfig', newConfig)
            setConfig(newConfig)
        }
    }, [initConfig]);

    useEffect(() => {
        if(config) {
            if(Array.isArray(unfilteredData)) { //no one is using this at this moment
                const filteredData = filterArray(unfilteredData)
                onFiltered(filteredData, config)
            } else {
                const filteredData = Object.keys(unfilteredData).reduce((acc, objKey) => {
                    const filtered = filterArray(unfilteredData[objKey])
                    return {...acc, [objKey]: filtered}
                } ,{})
                onFiltered(filteredData, config)
            }
        }
    }, [config]);

    useEffect(() => {
        if(config && isMobile) {
            setCollapseConfig(Object.keys(config).reduce((acc, filterType) => {
                if(config[filterType].options.length <= 1 && !config[filterType].enableSingleSelection) return acc
                return config[filterType].outside ? acc : [...acc, getCollapseItem(filterType)]
            },[]))
        }
    }, [config]);

    const getChild = (filterType, alwaysUseSelect = false) => {
        if(config[filterType].isRadio && !alwaysUseSelect) {
            return (
                <Radio.Group onChange={(e) => onSelectionChanged(filterType, e.target.value)} value={config[filterType].selected}>
                    <Space direction="vertical">
                        {config[filterType].options.map(option => <Radio key={`filter-radio-${option.value}`} value={option.value}>{option.label}</Radio>)}
                    </Space>
                </Radio.Group>
            )
        } else {
            let defaultVal = config[filterType].defaultValue ?? config[filterType].selected;
            if(config[filterType].isMulti && config[filterType].selected.length > 0) {
                defaultVal = config[filterType].selected
            } else if(!config[filterType].isMulti && config[filterType].selected) {
                defaultVal = config[filterType].selected
            }
            return <CustomSelect onChange={(val) => onSelectionChanged(filterType, val)} style={{width: '100%'}} maxTagCount={'responsive'} allowClear={config[filterType].isMulti} {...config[filterType]} defaultValue={defaultVal}/>
        }
    }

    const getCollapseItem = (filterType) => {
        const label = config[filterType].label ?? t(`filter-type-${filterType}`)
        return {
            key: filterType,
            label: <SmallHeader fontSize={'16px'}>{label}{config[filterType].isMulti && config[filterType].selected.length > 0 ? ` (${config[filterType].selected.length})` : ''}</SmallHeader>,
            children: getChild(filterType),
        }
    }

    const filterArray = (arr) => {
        let filtered = arr.filter(item => {
            return Object.keys(config).reduce((acc, filterType) => {
                if(!initConfig[filterType]?.filterFunc) return acc
                return config[filterType].selected.length > 0 ? (acc && initConfig[filterType]?.filterFunc(item, config[filterType].selected)) : acc
            }, true)
        })

        if(initConfig.sortOption) {
            filtered = initConfig.sortOption.sortFunc(arr, config.sortOption.selected, orderBy)
        }
        return filtered;
    }

    const onSelectionChanged = (filterType, selection) => {
        if(selection) {
            if(pageName === 'group') {
                handleQueryParam(filterType, selection)
            }
            const newFilterType = {...config[filterType], selected: selection}
            setConfig(prev => ({...prev, [filterType]: newFilterType}))
        }
    }

    const handleQueryParam = (filterType, selection) => {
        if(filterType !== 'locations') {
            let newQueryObj = {...filtersQueryObj}
            const filter = config[filterType]
            if(filter.isMulti) {
                if(selection.length === 0) {
                    delete newQueryObj[filterType]
                } else {
                    newQueryObj[filterType] = selection
                }
            } else {
                if(selection === filter.defaultValue) {
                    delete newQueryObj[filterType]
                } else {
                    newQueryObj[filterType] = selection
                }
            }
            setFiltersQueryObj(newQueryObj)
        }
    }

    const resetFilters = () => {
        setConfig(JSON.parse(JSON.stringify(initConfig)))
        setFiltersQueryObj({})
        setOpenMobileFilters(false)
    }

    const resetInnerFilters = () => {
        const newConfig = Object.keys(config).reduce((acc, filterType) => ({...acc, [filterType]: !config[filterType].outerDisplay ? initConfig[filterType] : config[filterType]}), {})
        setConfig(newConfig)
        setFiltersQueryObj({})
        setOpenDesktopFilters(false)
    }

    const getFiltersPopupTitle = () => {
        return (
            <FlexRowSection justify={'space-between'} align={'start'}>
                <SmallHeader fontSize={'24px'}>{t('filters')}</SmallHeader>
                <ClickableDiv onClick={() => setOpenDesktopFilters(false)}><FontAwesomeIcon icon={light('xmark')} color={Colors.siteBlack}/></ClickableDiv>
            </FlexRowSection>
        )
    }

    const getFiltersPopupContent = () => {
        return (
            <FlexColumnSection gap={'20px'}>
                <FlexColumnSection gap={'20px'} overflow={'auto'} maxHeight={'300px'}>
                    {Object.keys(config).map(filterType => {
                        if(config[filterType].options.length <= 1 && !config[filterType].enableSingleSelection) return null
                        return !config[filterType].outerDisplay &&
                        <FlexColumnSection gap={'5px'} overflow={'unset'}>
                            <SmallHeader fontSize={'16px'}>{config[filterType].label ?? t(`filter-type-${filterType}`)}{config[filterType].isMulti && config[filterType].selected.length > 0 ? ` (${config[filterType].selected.length})` : ''}</SmallHeader>
                            {getChild(filterType, true)}
                        </FlexColumnSection>
                    })}
                </FlexColumnSection>
                <FlexRowSection justify={'end'} gap={'10px'}>
                    <Btn onClick={resetInnerFilters}>{t('clear')}</Btn>
                    <Btn onClick={() => setOpenDesktopFilters(false)} type={'primary'}>{t('apply-filters')}</Btn>
                </FlexRowSection>
            </FlexColumnSection>
        )
    }

    return (
        <>{
            isMobile ?
                <>
                    <FlexRowSection gap={'10px'} flex={'unset'} justify={'space-between'}>
                        <FlexRowSection gap={'10px'}>{config && Object.keys(config).map(filterType => config[filterType].outside ? <CustomSelect key={`filter-select-${filterType}`} onChange={(val) => onSelectionChanged(filterType, val)} style={{flex: 1, maxWidth: '220px'}} maxTagCount={'responsive'} {...config[filterType]}/> : null)}</FlexRowSection>
                        {collapseConfig?.length > 0 && <div onClick={() => setOpenMobileFilters(true)}><FontAwesomeIcon icon={hasActiveFilters ? solid('filter') : light('filter')} color={Colors.siteBlack} size={'lg'}/></div>}
                    </FlexRowSection>
                    <Drawer
                        placement={'bottom'}
                        closable={false}
                        onClose={() => setOpenMobileFilters(false)}
                        open={openMobileFilters}
                        style={{borderTopLeftRadius: 20, borderTopRightRadius: 20}}
                        title={<SmallHeader>{t('filters')}</SmallHeader>}
                        extra={hasActiveFilters ? <span onClick={resetFilters} style={{fontSize: '14px'}}>{t('clear')}</span> : null}
                        className={'mobile-filters-drawer'}
                        destroyOnClose={true}
                    >
                        <FlexColumnSection gap={'20px'}>
                            <Collapse accordion
                                      items={collapseConfig}
                                      bordered={false}
                                      style={{background: Colors.white}}
                                      className={'mobile-filters-collapse'}
                                      expandIconPosition={'end'}
                                      expandIcon={({ isActive }) => <FontAwesomeIcon icon={light('chevron-down')} color={Colors.siteBlack} flip={isActive ? 'vertical' : 'horizontal'} size={'xs'}/>}
                            />
                            <Btn type={'primary'} onClick={() => setOpenMobileFilters(false)} width={'100%'}>{t('view-sessions')}</Btn>
                        </FlexColumnSection>
                    </Drawer>
                </>
                :
                <>
                    {oldStyle ? (config && <FlexRowSection gap={'10px'}>{Object.keys(config).map(filterType => <CustomSelect key={`filter-select-${filterType}`} onChange={(val) => onSelectionChanged(filterType, val)} style={{flex: 1, maxWidth: '150px'}} maxTagCount={'responsive'} {...config[filterType]}/>)}</FlexRowSection>)
                        :
                    <FlexRowSection gap={'10px'}>
                        {config && Object.keys(config).map(filterType => config[filterType].outerDisplay && <CustomSelect key={`filter-select-${filterType}`} onChange={(val) => onSelectionChanged(filterType, val)} style={{flex: 1, maxWidth: '150px'}} maxTagCount={'responsive'} {...config[filterType]}/>)}
                        {hasInnerFilters &&
                            <Popover placement="bottom"
                                     trigger="click"
                                     open={openDesktopFilters}
                                     onOpenChange={setOpenDesktopFilters}
                                     title={getFiltersPopupTitle()}
                                     content={getFiltersPopupContent()}
                                     overlayClassName={'desktop-filters-popup'}
                                     overlayInnerStyle={{padding: '20px'}}
                                     overlayStyle={{width: '460px'}}
                                     destroyTooltipOnHide={true}
                            >
                                <FilterBtn>
                                    {numActiveInnerFilters === 0 ? t('filters') : numActiveInnerFilters === 1 ? t('1-applied-filter') : t('num-applied-filters', {num: numActiveInnerFilters})}
                                    <FontAwesomeIcon icon={numActiveInnerFilters === 0 ? light('filters') : solid('filters')} color={Colors.siteBlack}/>
                                </FilterBtn>
                            </Popover>
                        }
                    </FlexRowSection>
                    }
                </>
            }
        </>
    );
};

export default FiltersHandler;

const FilterBtn = styled.div`
    padding: 0 15px;
    background-color: white;
    height: 36px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border: 1px solid ${Colors.siteScheduleBorder};
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    :hover {
        border: 1px solid ${Colors.greyText};
    }
`;

