import {CustomFieldContainerSessionInfo, CustomFieldItemSessionInfo} from "../styles/globalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import React, {useMemo} from "react";
import useScreenSize from "../helpers/hooks/useScreenSize";
import {getDisabledPagesProps} from "@arboxappv4/shared/src/helpers/DisablePages";

const CustomFieldsDataDisplay = (props) => {
    const { data } = props;
    const {isMobile} = useScreenSize();
    const customFieldsData = data.custom_field_value?.length > 0 ? data.custom_field_value : data.series?.custom_field_value;
    // const numberDotsPerRow = isMobile ? 2 : 3; TODO if we will want to bring back dots and line breaks
    const customFieldsDataFiltered = customFieldsData?.filter(field => field.value);

    return (
        customFieldsDataFiltered && customFieldsDataFiltered.length > 0 &&
        <CustomFieldContainerSessionInfo mobile={isMobile}>
            {customFieldsDataFiltered.map((field,index) => {
                const value = field.custom_field.props.options.find(option => option.value === field.value)?.label;
                return <CustomFieldItemSessionInfo>
                            {field.custom_field.name}: {value}
                            {/*/!*{index !== customFieldsData.length -1 && ((index + 1) % numberDotsPerRow !== 0) &&*!/ TODO if we will want to bring back dots and line breaks*/}
                            {
                                index !== customFieldsDataFiltered.length -1 &&
                                <FontAwesomeIcon icon={solid('circle-small')} color={Colors.siteBlack} className={'dot-icon'} style={{ padding: '10px' }}/>
                            }
                        </CustomFieldItemSessionInfo>

            })}
        </CustomFieldContainerSessionInfo>
    );
};

export default CustomFieldsDataDisplay;
